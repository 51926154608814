import GroupService from '@/services/group.service'
import EmailValidationService from '@/services/email-validation.service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import { ChannelType } from '@/models/ChannelType'

export default {
  name: 'DdbbValidate',
  components: {
    HeaderTopDashboard,
    MultiSelect,
  },
  data: function () {
    return {
      count: {
        browsers: 0,
        devices: 0,
        error: 0,
        landlines: 0,
        mails: 0,
        phones: 0,
        total: 0,
        invalid: 0,
      },
      checked: true,
      stepperStep: 1,
      groups: [],
      dataAllGroups: [],
      groupsIds: [],
      ddbbOption: {
        check: 2,
        risky: false,
      },
      validateCost: 0,
      isSubmitted: false,
    }
  },
  methods: {
    setValidationCost () {
      EmailValidationService.getValidationCost().then((data) => {
        this.validateCost = parseFloat(data.validateCost)
      })
    },
    getGroups () {
      const params = {
        channelType: ChannelType.MAILING_ID,
      }
      GroupService.getUserGroups(params)
        .then(
          (response) => {
            this.dataAllGroups = response
          },
          () => { },
        )
        .finally(() => {
        })
    },
    check (card) {
      this.ddbbOption.check = card
      this.checked = false
      this.$nextTick(() => {
        this.checked = true
      })
    },
    showCount () {
      const param = {
        groups: this.groupsIds,
      }
      GroupService.showCount(param)
        .then(
          (response) => {
            this.count.browsers = response.browsers
            this.count.devices = response.devices
            this.count.error = response.error
            this.count.landlines = response.landlines
            this.count.mails = response.mails
            this.count.phones = response.phones
            this.count.total = response.total
          },
          () => { },
        )
        .finally(() => {
        })
    },
    submitData () {
      this.isSubmitted = true
      const param = {
        groupIds: this.groupsIds,
        action: this.ddbbOption.check,
        allowRisky: this.ddbbOption.risky,
      }
      GroupService.submitData(param)
        .then(
          () => {
            this.getUserBalance()
            this.$router.push('/tools/ddbbvalidations')
          },
          () => { },
        )
    },
    async getUserBalance () {
      try {
        await this.$store.dispatch('balance/getBalance')
      } catch (error) {
        console.error(error)
      }
    },
    emailCount () {
      const param = {
        groupIds: this.groupsIds,
      }
      GroupService.emailCount(param)
        .then(
          (response) => {
            this.count.invalid = response.notValidated
            this.count.total = response.all
          },
          () => { },
        )
        .finally(() => {
        })
    },
    step2 () {
      this.stepperStep = 2
      this.emailCount()
    },
    selectedGroups (newGroups) {
      this.groupsIds = newGroups.map(g => g.id)
      this.showCount()
    },
  },
  computed: {
  },
  created () {
    this.getGroups()
    this.setValidationCost()
  },
  mounted () {
  },
}
